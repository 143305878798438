import useVideoPlayerStrataEvents from 'utils/useVideoPlayerStrataEvents';
import useVideoPlayerPlaylistController from 'utils/useVideoPlayerPlaylistController';
import useSaveShowBookmark from 'utils/useSaveShowBookmark';
import React from 'react';
import VideoPlayerComponent from '../VideoPlayerComponent';
import { VideoPlayerBasicProps } from '../types';
import VideoPlayerSkipFrames from '../VideoPlayerSkipFrames/index.module';

interface VideoPlayerShowComponentProps extends VideoPlayerBasicProps {}

const VideoPlayerShowComponent = (props: VideoPlayerShowComponentProps) => {
  useVideoPlayerPlaylistController();
  useSaveShowBookmark();
  const { onAdEnd, onAdStart, onAdPause, ...strataEventsHandlers } =
    useVideoPlayerStrataEvents();

  return (
    <VideoPlayerComponent
      Frames={
        <VideoPlayerSkipFrames
          onAdEnd={onAdEnd}
          onAdStart={onAdStart}
          onAdPause={onAdPause}
        />
      }
      {...strataEventsHandlers}
      {...props}
    />
  );
};

export default React.memo(VideoPlayerShowComponent);
