import {
  AssetType,
  ContentType,
} from '@laminar-product/client-commons-core/core';
import React from 'react';
import VideoPlayerComponent from 'library/VideoPlayer/VideoPlayerComponent';
import VideoPlayerMovieComponent from '../VideoPlayerMovieComponent';
import VideoPlayerShowComponent from '../VideoPlayerShowComponent';
import VideoPlayerLiveComponent from '../VideoPlayerLiveComponent';
import { VideoPlayerBasicProps } from '../types';

interface VideoPlayerFactoryComponentProps {
  assetType: AssetType;
  contentType: ContentType;
  commonProps: VideoPlayerBasicProps;
}
const VideoPlayerFactoryComponent = ({
  assetType,
  contentType,
  commonProps,
}: VideoPlayerFactoryComponentProps) => {
  if (![ContentType.MAIN, ContentType.LIVE].includes(contentType)) {
    return <VideoPlayerComponent {...commonProps} />;
  }
  switch (assetType) {
    case AssetType.MOVIE:
      return <VideoPlayerMovieComponent {...commonProps} />;
    case AssetType.EPISODE:
      return <VideoPlayerShowComponent {...commonProps} />;
    case AssetType.LIVE_EVENT:
    case AssetType.LIVE_CHANNEL:
      return <VideoPlayerLiveComponent {...commonProps} />;
    default:
      return <VideoPlayerComponent {...commonProps} />;
  }
};

export default React.memo(VideoPlayerFactoryComponent);
