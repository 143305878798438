import { Asset, Content } from '@laminar-product/client-commons-core/core';
import useVideoPlayerConfiguration from 'utils/useVideoPlayerConfiguration';
import useVideoPlayerCommonEvents from 'utils/useVideoPlayerCommonEvents';
import useVideoPlayerUI from 'utils/useVideoPlayerUI';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import { resetPlayer } from 'store/player/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { selectPlayerError } from 'store/player/selectors';
import usePlayerGoBack from 'utils/usePlayerGoBack';
import useBackHandler from 'utils/useBackHandler';
import VideoPlayerError from '../VideoPlayerError';
import VideoPlayerOverlay from '../VideoPlayerOverlay';
import VideoPlayerFactoryComponent from './VideoPlayerFactoryComponent';

interface VideoPlayerFactoryProps {
  content: Content;
  asset: Asset;
  loop?: boolean;
  playerId: string;
}

const VideoPlayerFactory = ({
  content,
  asset,
  loop,
  playerId,
}: VideoPlayerFactoryProps) => {
  const dispatch = useDispatch();
  const uiConfig = useVideoPlayerUI();
  const videoError = useSelector(selectPlayerError);
  const playerLoadConfiguration = useVideoPlayerConfiguration({
    assetId: asset.id,
    content,
    variant: 'primary',
  });

  const { onPlayerInitialized, onPlayerError, setPlayerState } =
    useVideoPlayerCommonEvents({
      asset,
      content,
      playerLoadConfiguration,
    });

  const goBackFromPlayer = usePlayerGoBack(asset);
  useBackHandler(goBackFromPlayer);

  const commonProps = useMemo(
    () => ({
      loadConfig: playerLoadConfiguration!,
      loop,
      playerId,
      uiConfig,
      onPlayerInitialized,
      onPlayerError,
      setPlayerState,
      Overlay: <VideoPlayerOverlay />,
    }),
    [
      loop,
      onPlayerError,
      onPlayerInitialized,
      playerId,
      playerLoadConfiguration,
      setPlayerState,
      uiConfig,
    ]
  );

  useEffect(() => {
    return () => {
      dispatch(resetPlayer());
    };
  }, [dispatch]);

  if (videoError) {
    return (
      <VideoPlayerError
        errorCode={videoError.errorCode}
        onClose={goBackFromPlayer}
      />
    );
  }

  if (!playerLoadConfiguration || !uiConfig) {
    return <AppLoadingIndicator fullScreen />;
  }

  return (
    <VideoPlayerFactoryComponent
      assetType={asset.type}
      commonProps={commonProps}
      contentType={content.type}
    />
  );
};

export default VideoPlayerFactory;
